// @flow
import styled, { css, keyframes } from 'styled-components';

const shrinkToTop = keyframes`
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
`;
const expandFromTop = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
`;

const searchAnimationExpand = css`animation: ${expandFromTop} 0.21s ease-in;`;
const searchAnimationShrink = css`animation: ${shrinkToTop} 0.21s ease-out;`;

const SearchModal = styled.div.attrs((props: {
  isHiding: boolean,
  isVisible: boolean,
  isMenuVisible: boolean,
  animation: string,
}) => {
  let animation = 'animation: none;';
  if (!props.isMenuVisible) {
    if (props.isHiding) {
      animation = searchAnimationShrink;
    } else if (props.isVisible) {
      animation = searchAnimationExpand;
    }
  }
  return { animation };
})`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 0;
  overflow-y: auto;
  z-index: 100050;
  ${(props) => props.animation};
  
  .search-bar-outer {
    height: var(--navbar-height);

    .search-bar {
      height: var(--navbar-height);
      display: flex;
      position: absolute;
      left: 20px;
      right: 20px;
      align-items: center;
      
      .search-icon {
        flex: 0 0 50px;
      }
      
      .search-input {
        flex: 1;

        input {
          margin-top: 12px;
          border-color: var(--color-border-input) !important;
          width: 100%;
        }
      }
      
      .cancel-button {
        display: none;
      }
    }
  }
  
  .search-results {
    position: absolute;
    top: var(--navbar-height);
    padding-top: 16px;
    left: 20px;
    right: 20px;
    
    .result {
      border-color: var(--color-border-input);

      .highlight {
        background-color: #F6E1B5;
      }
    }
    
    .may-be-missing-results {
      color: #8e8e8e;
    }
  }
  
  background: #fff8e9;
  @media (min-width: 992px) {
    background: #ffffff;
    
    .search-bar, .search-results {
      max-width: 680px;
      margin: auto;
    }
    
    .search-bar-outer {
      box-shadow: 0 2px 27px 12px rgba(0,0,0,0.02);
      
      .search-bar {
        .search-input {
          input {
            margin-top: 6px;
            border: none;
          }
        }
        
        .cancel-button {
          display: inline-block;
          margin-top: -10px;
          
          &::before,
          &::after {
            background: #a3a3a3;
            width: 14px;
          }
          
          &::after {
            left: -14px;
          }
        }
      }
    }
    
    .search-results {
      padding-left: 50px;
      padding-right: 20px;

      .result {
        border-color: #ccc;
        
        .highlight {
          background-color: #E6F3ED;
        }
      }
    }
    
    .cancel-button {
      display: inline-block;
    }
  }
`;

export default SearchModal;
