// @flow
import * as React from 'react';
import styled from 'styled-components';

// Props spreading is ok here as we are only passing whitelisted valid HTML props here
// eslint-disable-next-line react/jsx-props-no-spreading
const Element = styled(({ variation, ...rest }) => <span {...rest} />)`
  color: ${(props) => `var(--color-text-${props.variation})`}
`;

export type TextStyleVariation = 'positive' | 'negative' | 'warning' | 'neutral';

type Props = {
  children: React.Node,
  variation: TextStyleVariation,
  as?: React.ComponentType<any> | string,
  className?: string,
};

const defaultProps = {
  as: 'span',
  className: '',
};

const TextStyle = ({
  children,
  variation,
  as,
  className,
}: Props) => (
  <Element
    className={className}
    as={as}
    variation={variation}
  >
    {children}
  </Element>
);

TextStyle.defaultProps = defaultProps;

export default TextStyle;
