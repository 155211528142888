// @flow
import * as React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import type { Properties as CSSProperties } from 'csstype';

import { TextButton } from '../styles';

const StyledButton = styled(TextButton)`
  display: inline-flex;
  align-items: center;
  font-size: inherit;
  font-weight: normal;
`;

const ToggleIcon = styled.span`
  position: relative;
  width: 10px;
  height: 7px;
  margin-left: 7px;
  flex: 0 0 10px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 8px;
    height: 2px;
    margin-top: ${(props) => (props.active ? '2px' : '-3px')};
    border-bottom: 2px solid;
    transform: ${(props) => (props.active ? 'rotate(-45deg)' : 'rotate(45deg)')};
    transform-origin: center left;
    transition: all 0.1s linear;
  }

  &::after {
    left: auto;
    right: 0;
    transform: ${(props) => (props.active ? 'rotate(45deg)' : 'rotate(-45deg)')};
    transform-origin: center right;
    transition-timing-function: linear;
  }
`;

type Props = {
  children: React.Node,
  active: boolean,
  // eslint-disable-next-line react/require-default-props
  style?: CSSProperties<HTMLElement>,
  // eslint-disable-next-line react/require-default-props
  onClick?: () => void,
  // eslint-disable-next-line react/require-default-props
  className?: string,
};

const ToggleButton = React.forwardRef<Props, any>(({
  children,
  active,
  style = null,
  onClick = () => null,
  className = '',
}: Props, ref) => (
  <StyledButton ref={ref} className={className} style={style} onClick={onClick}>
    {children}
    <ToggleIcon active={active} />
  </StyledButton>
));

export const UnderlinedToggleButton = styled(ToggleButton)`
  position: relative;
  min-width: 50px;
  margin: 0 5px;
  font-size: inherit;
  line-height: inherit;
  box-shadow: inset 0 -2px #000;
  transition: box-shadow 0.4s cubic-bezier(0.15, 0.53, 0.35, 1);

  span:first-of-type {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:focus,
  &:hover {
    color: #000;
    box-shadow: inset 0 -4px #000;
  }
`;

export default ToggleButton;
