/**
 * Exports a number of authentication / login related functions.
 * They communicate with our API as necessary.
 *
 * @flow
 */

import { navigate } from 'gatsby';
import { apiUrl, communityUrl } from '../../config';
import { postJson } from '../utils';

const isBrowser = () => typeof window !== 'undefined';

export type UserData = {
  email: string,
  username: string,
  name?: string,
  userId: string,
  avatarTemplate: string,
  justLoggedIn: boolean,
};

let userData = null; // cache
export const getUserData = (): ?UserData => {
  if (userData) {
    return userData;
  }

  if (isBrowser() && window.localStorage.getItem('userData') !== null) {
    const {
      email, username, name, user_id: userId, avatar_template: avatarTemplate,
    } = JSON.parse(window.localStorage.getItem('userData'));

    const justLoggedIn = window.localStorage.getItem('justLoggedIn');
    userData = {
      email, username, name, userId, avatarTemplate, justLoggedIn,
    };
    return userData;
  }

  // Not logged in
  userData = null;
  return userData;
};

export const loginRedirect = (preparedLoginData: { success: boolean, redirect: string }) => {
  // Navigate to the SSO redirect URL
  window.location.href = preparedLoginData.redirect;
};

export const prepareLogin = (
  successCallback: (preparedLoginData: { success: boolean, redirect: string }) => void,
  errorCallback: () => void = () => {
    // This is unlikely to fail, so using alert even though it's not pretty
    // eslint-disable-next-line no-alert
    alert('Unable to log in / sign up at the current time. Please check that you are online.');
  },
  hashFragment: string = '',
) => {
  // Get an SSO redirect URL from our API
  postJson(`${apiUrl}/prepareLogin`, {
    return_sso_url: window.location.href.replace(/#.*/, '')
      + (hashFragment ? `${window.location.href.includes('?') ? '&' : '?'}fragment=${encodeURIComponent(hashFragment)}` : ''),
  }, successCallback, errorCallback);
};

export const checkForSingleSignOn = (
  successCallback: () => void,
  // Optionally setState function:
  setVerifyingLogin: (boolean) => void = () => {},
  errorCallback: () => void = () => {
    // This is unlikely to fail, so using alert even though it's not pretty
    // eslint-disable-next-line no-alert
    alert('Sorry, login failed! Please contact us');
  },
) => {
  if (isBrowser()) {
    const urlParams = new URLSearchParams(window.location.search);
    const ssoParam = urlParams.get('sso');
    const sigParam = urlParams.get('sig');
    const fragmentParam = urlParams.get('fragment');
    if (ssoParam != null && sigParam != null) {
      setVerifyingLogin(true);
      // User has redirected from a Discourse SSO
      // Validate the parameters against our API
      postJson(`${apiUrl}/validateLogin`,
        { sso: ssoParam, sig: sigParam },
        (data) => {
          if (typeof data.username === 'undefined') {
            setVerifyingLogin(false);
            errorCallback();
          } else {
            // Save result to localStorage
            window.localStorage.setItem('sso', ssoParam);
            window.localStorage.setItem('sig', sigParam);
            window.localStorage.setItem('userData', JSON.stringify(data));
            window.localStorage.setItem('justLoggedIn', true);

            setVerifyingLogin(false);
            successCallback();

            // Inform components that may need to update
            window.dispatchEvent(new Event('ethical-login'));

            // Eliminate params from URL
            urlParams.delete('sso');
            urlParams.delete('sig');
            if (fragmentParam) {
              // Hash fragments are not supported by the SSO redirect.
              // If there is a fragment it will be added after a hash symbol.
              urlParams.delete('fragment');
            }
            navigate(`${window.location.pathname}`
              + `${urlParams.toString() ? `?${urlParams.toString()}` : ''}`
              + `${fragmentParam ? `#${fragmentParam}` : ''}`);
          }
        }, errorCallback);
    }
  }
};

export const logout = (
  successCallback: () => void,
  errorCallback: () => void = () => {
    // This is unlikely to fail, so using alert even though it's not pretty
    // eslint-disable-next-line no-alert
    alert('Sorry, logout failed! Please check your network connection.');
  },
) => {
  if (isBrowser() && window.localStorage.getItem('userData') !== null) {
    const sso = window.localStorage.getItem('sso');
    const sig = window.localStorage.getItem('sig');

    postJson(`${apiUrl}/logout`, { sso, sig }, () => {
      window.localStorage.removeItem('userData');
      userData = null;
      window.localStorage.removeItem('sso');
      window.localStorage.removeItem('sig');

      // Inform components that may need to update
      window.dispatchEvent(new Event('ethical-logout'));

      successCallback();
    }, errorCallback);
  }
};

export const dismissJustLoggedIn = () => {
  window.localStorage.removeItem('justLoggedIn');
};

export const openUserSettings = () => {
  if (isBrowser() && window.localStorage.getItem('userData') !== null) {
    const { username } = JSON.parse(window.localStorage.getItem('userData'));
    window.location.href = `${communityUrl}/u/${username}/preferences`;
  }
};
