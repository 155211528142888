/* eslint-disable react/require-default-props */
// @flow
import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import uniqueId from 'lodash.uniqueid';
import classnames from 'classnames';

import TextStyle from './TextStyle';

export const Wrapper = styled.div`
  position: relative;
  display: flex; /* to make the margin property work in the child elements */
  width: 100%;
  margin-top: 25px;

  & + & {
    margin-top: 35px;
  }
`;

export const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: inline-block;
  margin: 0;
  background: #fff;
  color: var(--color-text-input-placeholder);
  transform-origin: left center;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform, padding, color;
  pointer-events: none;
  user-select: none;
`;

const ErrorMessage = styled.div``;

const Input = styled(({
  large,
  error,
  hasValue,
  ...rest
// Props spreading is ok here as we are only passing whitelisted valid HTML props here
// eslint-disable-next-line react/jsx-props-no-spreading
}) => <input {...rest} />)`
  display: inline-block;
  margin: 0;
  padding: 0 0 5px;
  font-size: 14px;
  color: var(--color-text-input);
  background: none;
  border: 0;
  border-bottom: 2px solid ${(props) => (props.error ? 'var(--color-border-input-error)' : 'var(--color-border-input)')};
  border-radius: 0; /* reset border-radius in iOS Safari */
  outline: 0;
  box-shadow: none;

  &:focus,
  &:hover:not(:disabled) {
    outline: 0;
    box-shadow: none;
    border-bottom-color: ${(props) => (props.error ? 'var(--color-border-input-error)' : 'var(--color-border-input-focus)')};
  }
  
  &:disabled {
    border-bottom-color: #cccccc;
  }

  &:focus + ${Label},
  &.TextField--hasValue + ${Label} {
    transform: translateY(-90%) scale(0.75);
  }

  &::placeholder {
    color: #8e8e8e;
  }

  @media (min-width: 768px) {
    padding-bottom: 7px;
    font-size: ${(props) => (props.large ? '24px' : '16px')};

    + ${Label} {
      font-size: ${(props) => (props.large ? '24px' : '16px')};
    }
  }
`;

const Textarea = styled(Input).attrs(() => ({ as: 'textarea' }))`
  width: 100%;
  padding: 19px 17px;
  border: 1px solid ${(props) => (props.error ? 'var(--color-border-textarea-error)' : 'var(--color-border-textarea)')};
  border-bottom: 2px solid ${(props) => (props.error ? 'var(--color-border-input-error)' : 'var(--color-border-input)')};
  border-radius: 4px 4px 0 0;

  + ${Label} {
    left: 17px;
    transform: translateY(19px);
  }

  &:focus,
  &:hover:not(:disabled) {
    border-color: ${(props) => (props.error ? 'var(--color-border-textarea-error)' : 'var(--color-border-textarea-focus)')};
    border-bottom-color: ${(props) => (props.error ? 'var(--color-border-input-error)' : 'var(--color-border-input-focus)')};
  }

  &:focus + ${Label},
  &.TextField--hasValue + ${Label} {
    padding: 0 4px;
    transform: translateY(-50%) scale(0.75);
  }
`;

export type InputType =
  | 'text'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'time'
  | 'week';

type Props = {
  label?: string,
  value?: string,
  type?: InputType,
  name?: string,
  id?: string,
  className?: string,
  large?: boolean,
  placeholder?: string,
  multiline?: boolean | number,
  error?: boolean | string,
  role?: string,
  step?: number,
  autoComplete?: boolean | string,
  max?: number | string,
  maxLength?: number,
  min?: number | string,
  minLength?: number,
  pattern?: string,
  spellCheck?: boolean,
  disabled?: boolean,
  required?: boolean,
  readOnly?: boolean,
  autoFocus?: boolean,
  focused?: boolean,
  onChange?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onKeyPress?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onKeyDown?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onKeyUp?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onFocus?: () => void,
  onBlur?: () => void,
};

const defaultProps = {
  type: 'text',
  multiline: false,
  error: false,
  large: false,
};

const TextField = (props: Props) => {
  const {
    label,
    value,
    type,
    name,
    id: idProp,
    className,
    large,
    placeholder,
    multiline,
    error,
    role,
    step,
    autoComplete,
    max,
    maxLength,
    min,
    minLength,
    pattern,
    spellCheck,
    disabled,
    required,
    readOnly,
    autoFocus,
    focused,
    onChange,
    onKeyPress,
    onKeyDown,
    onKeyUp,
    onFocus,
    onBlur,
  } = props;

  const [hasValue, setHasValue] = useState<boolean>(!!value);

  const handleChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    setHasValue(!!event.currentTarget.value);

    if (onChange && typeof onChange === 'function') {
      onChange(event);
    }
  });

  const id = useMemo(() => idProp || uniqueId('TextField'), [idProp]);

  const rows = typeof multiline === 'boolean' ? 8 : multiline;

  const labelMarkup = (
    <Label id={`${id}Label`} htmlFor={id}>{label}</Label>
  );

  const errorMarkup = error && typeof error === 'string' ? (
    <ErrorMessage id={`${id}Error`}>
      <TextStyle variation="negative">{error}</TextStyle>
    </ErrorMessage>
  ) : null;

  const FieldComponent = multiline ? Textarea : Input;

  const inputMarkup = (
    <FieldComponent
      value={value}
      type={!multiline ? type : undefined}
      name={name}
      id={id}
      className={classnames(hasValue && 'TextField--hasValue')}
      large={large}
      placeholder={placeholder}
      error={error}
      rows={multiline ? rows : undefined}
      role={role}
      step={step}
      autoComplete={autoComplete}
      max={max}
      maxLength={maxLength}
      min={min}
      minLength={minLength}
      pattern={!multiline ? pattern : undefined}
      spellCheck={spellCheck}
      disabled={disabled}
      required={required}
      readOnly={readOnly}
      autoFocus={autoFocus}
      focused={focused}
      aria-describedby={errorMarkup ? `${id}Error` : undefined}
      aria-invalid={error ? true : undefined} // The value of error may also be string
      aria-multiline={multiline}
      onChange={handleChange}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );

  return (
    <Wrapper className={className}>
      {inputMarkup}
      {labelMarkup}
      {errorMarkup}
    </Wrapper>
  );
};

TextField.defaultProps = defaultProps;

export default TextField;
