/**
 * Displays a short message in a light-box.
 * Used to indicate some processing is taking place.
 * Also blocks the user from interacting with the page.
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';
import { OverlayPanel, LightboxOuter, LightboxInner } from '../styles';

const LightboxInnerStyled = styled(LightboxInner)`
  padding: 10px;

  p {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

type Props = {
  message: string,
};

const LoadingIndicator = ({ message }: Props) => (
  <>
    <OverlayPanel />
    <LightboxOuter>
      <LightboxInnerStyled>
        <p>{message}</p>
      </LightboxInnerStyled>
    </LightboxOuter>
  </>
);

export default LoadingIndicator;
