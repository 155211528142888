// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.Node,
  as?: React.ComponentType<any> | string,
  className?: string,
};

const defaultProps = {
  as: 'div',
  className: '',
};

const ScreenReaderText = ({
  as: Component = 'div',
  className,
  children,
}: Props) => (
  <Component className={classNames('screen-reader-text', className)}>{children}</Component>
);

ScreenReaderText.defaultProps = defaultProps;

export default ScreenReaderText;
