/**
 * Displays a short message which will fade out
 *
 * @flow
 */
import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeOutOpacity = keyframes`
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const FadingMessageOuter = styled.div`
  position: absolute;
  z-index: 19000;
  top: var(--navbar-height);
  left: 0;
  right: 0;
  opacity: 1;
  animation: ${fadeOutOpacity} 2s ease-in;
  animation-fill-mode: both;
`;
const FadingMessageInner = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
  margin-top: 1rem;
  margin-right: 1rem;
  background-color: #f3faf7;
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.05);
  text-align: center;
`;

type Props = {
  children: React.Node,
};

const FadingNotification = ({ children }: Props) => (
  <FadingMessageOuter>
    <FadingMessageInner>
      {children}
    </FadingMessageInner>
  </FadingMessageOuter>
);

export default FadingNotification;
