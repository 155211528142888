// @flow
import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${(props) => (props.fluid ? 'none' : 'var(--max-content-width)')};
  padding-right: var(--container-gutter-width);
  padding-left: var(--container-gutter-width);
  margin-right: auto;
  margin-left: auto;
`;

export const Button = styled.button.attrs(({ size = 'medium' }) => {
  let padding = '9px 22px';
  let fontSize = '15px';

  switch (size) {
    case 'medium':
      padding = '10px 25px';
      fontSize = '18px';
      break;

    case 'large':
      padding = '15px 32px';
      fontSize = '18px';
      break;

    default:
      break;
  }

  return { padding, fontSize, type: 'button' };
})`
  --button-border-radius: 72px;
  display: inline-block;
  padding: 9px 22px;
  font-family: var(--font-family-main);
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  color: var(--color-text-button);
  background: none;
  text-align: center;
  vertical-align: middle;
  border: 2px solid var(--color-border);
  border-radius: var(--button-border-radius);
  box-shadow: none;
  user-select: none;
  transition: 0.2s ease-in;
  transition-property: background, color, border-color;

  &:focus,
  &:hover {
    color: var(--color-primary);
    text-decoration: none;
    border-color: var(--color-primary);
    box-shadow: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  &:disabled,
  &.disabled {
    opacity: 0.2;
  }

  @media (min-width: 768px) {
    padding: ${(props) => props.padding};
    font-size: ${(props) => props.fontSize};
  }
`;

export const TextButton = styled(Button)`
  padding: 0;
  background: none;
  font-weight: normal;
  border: 0;
  border-radius: 0;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const AddButton = styled(Button)`
  position: relative;
  padding-left: 35px !important;
  color: #fff;
  background: #2fd165;
  border-color: #2fd165;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 18px;
    display: block;
    width: 10px;
    height: 2px;
    margin-top: -1px;
    background: #fff;
  }

  &::after {
    transform: rotate(90deg);
    transform-origin: center;
  }

  &:focus,
  &:hover {
    color: #fff;
    background: #000;
    border-color: #000;
  }

  @media (min-width: 768px) {
    padding-left: 44px !important;

    &::before,
    &::after {
      left: 22px;
    }
  }
`;

export const DropdownBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  background: #fff;
  box-shadow: 0 2px 27px 12px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
`;

export const NavbarDropdownBox = styled(DropdownBox)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 27px 27px 12px rgba(0, 0, 0, 0.02);

  a {
    padding: 7px 0;
    font-size: 18px;
  }
`;

export const PageHeader = styled.div`
  margin: var(--header-bottom-space) 0;

  @media (min-width: 768px) {
    margin-bottom: 33px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 57px;
  }
`;

export const IntroText = styled.p`
  font-family: var(--font-family-main);
  font-size: 17px;
  line-height: 23px;
  color: #8e8e8e;

  @media (min-width: 768px) {
    font-size: ${(props) => (props.small ? '18px' : '24px')};
    line-height: 33px;
  }
`;

export const Quote = styled.q`
  display: block;
  font-style: italic;
  quotes: "“" "”" "‘" "’";

  &::before {
    content: ${(props) => (props.noQuotes ? '' : 'open-quote')};
  }

  &::after {
    content: ${(props) => (props.noQuotes ? '' : 'close-quote')};
  }

  @media (min-width: 992px) {
    font-size: 19px;
  }
`;

export const postContentBoxCSS = css`
  max-width: var(--max-post-content-width);

  @media (min-width: 992px) {
    margin-left: auto;
    margin-right: 0;
  }
`;

export const pageContentBoxCSS = css`
  max-width: var(--max-post-content-width);

  @media (min-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const AvatarImg = styled.img`
  background-color: #eee;
  border-radius: 50%;
  flex-basis: 48px;
  width: 48px;
  height: 48px;
`;

// This element functions like a button, but looks more like a link:
export const LinkLikeButton = styled.button`
  display: inline;
  border: 0;
  background-color: transparent;
  color: var(${(props) => (props.selected ? '--color-text' : '--color-link')});
  font-weight: 500;
  font-size: 0.9em;
  padding-left: 0;
  padding-right: 0;
  line-height: 0.8em;
  padding-bottom: 4px;
  outline: none !important;
  
  text-decoration: none;
  border-bottom: 1px solid var(--color-button-decoration);
  transition: border 0.2s ease, padding 0.2s ease;

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: var(--color-link-hover);
    text-decoration: none;
    border-bottom: 4px solid var(--color-button-decoration);
    padding-bottom: 1px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const OverlayPanel = styled.div`
  position: fixed;
  background: #4a4a4a;
  opacity: 0.5;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 110000;
  animation: ${fadeIn} 0.2s ease-in;
`;

export const LightboxOuter = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 110001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.2s ease-in;
`;

export const LightboxInner = styled.div`
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 90%;
  max-width: 450px;
  border-radius: 4px;
`;

export const CancelButton = styled(TextButton)`
  display: inline-block;
  width: 44px;
  height: 44px;

  &::before,
  &::after {
    content: '';
    position: relative;
    top: 0;
    right: 0;
    margin-top: -1px;
    display: inline-block;
    width: 19px;
    height: 2px;
    background: var(--color-text-button);
    transform: rotate(45deg);
    transform-origin: center;
    transition: transform 0.2s 0.1s, margin-top 0.1s;
  }

  &::after {
    margin-top: -1px;
    transform: rotate(-45deg);
    left: -19px;
  }
`;
