/**
 * Displays user info such as name and avatar when the user is logged in.
 * Also provides Logout and Settings links.
 *
 * @flow
 */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { Button, AvatarImg } from '../../styles';
import { getUserData, openUserSettings, logout } from '../../services/auth';
import useMediaBreakpoints from '../../hooks/useMediaBreakpoints';
import LoadingIndicator from '../LoadingIndicator';

const UserDataInner = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: flex-start;
  padding: 4px;
  ${(props) => (props.reverse ? 'padding-left: 35px;' : '')}
`;
const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.reverse ? 'flex-end' : 'flex-start')};
  padding-right: 16px;
  padding-left: 16px;
`;
const UsersName = styled.div`
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
`;
const UserButtons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: ${(props) => (props.isDesktop ? '14px' : '16px')} !important;
  color: #666666;
`;
const UserButton = styled(Button)`
  border: 0;
  color: #666666;
  font-weight: normal;
  font-size: ${(props) => (props.isDesktop ? '14px' : '16px')} !important;
  padding: 2px !important;
`;
const UserLogoutButton = styled(UserButton)``;
const UserSettingsButton = styled(UserButton)``;

type Props = {
  reverse?: boolean,
  setLoggedIn: (boolean) => any,
};

const UserInfo = ({ reverse, setLoggedIn }: Props) => {
  const userData = getUserData();

  const [isLoggingOff, setLoggingOff] = useState(false);

  // If ?logout param is present, log the user off
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('logout') !== null) {
      setLoggingOff(true);
      logout(() => {
        setLoggedIn(false);
        setLoggingOff(false);
        navigate(window.location.pathname);
      });
    }
  });

  const {
    isDesktop,
  } = useMediaBreakpoints();

  const initLogout = () => {
    setLoggingOff(true);
    logout(() => {
      setLoggedIn(false);
      setLoggingOff(false);
    });
  };

  return userData ? (
    <UserDataInner reverse={reverse}>
      {userData.avatarTemplate && (
        <AvatarImg src={userData.avatarTemplate.replace('{size}', '96')} width="48" height="48" alt="" />
      )}
      <UserDetails reverse={reverse}>
        <UsersName>{userData.name || userData.username}</UsersName>
        <UserButtons isDesktop={isDesktop}>
          <UserLogoutButton isDesktop={isDesktop} onClick={initLogout}>Logout</UserLogoutButton>
          <> - </>
          <UserSettingsButton isDesktop={isDesktop} onClick={openUserSettings}>
            Settings
          </UserSettingsButton>
        </UserButtons>
      </UserDetails>

      {isLoggingOff && <LoadingIndicator message="Logging out of your Ethical Community account..." />}
    </UserDataInner>
  ) : null;
};

UserInfo.defaultProps = {
  reverse: false,
};

export default UserInfo;
