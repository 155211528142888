// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import elasticlunr from 'elasticlunr';
import Img from 'gatsby-image';
// Note: @reach/router is a gatsby dependency
// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate } from '@reach/router';

import TextField from './TextField';
import addHighlights from '../highlight';
import { Button, CancelButton } from '../styles';
import { paths } from '../../config';
import Link from './Link';
import TextStyle from './TextStyle';
import SearchIcon from '../images/search.svg';

type Props = {
  pagesMap: {
    [string]: { // path as key
      path: string,
      type: string,
      title: string,
      excerpt: string,
      image?: string,
      content?: string,
    }
  },
  showImages?: boolean,
  indexContent?: boolean,
  resultsLimit?: ?number,
  cancelSearch?: Function,
  loadingFullSearchPage?: Function,
  queryUpdated?: ?Function,
  searchQuery?: string,
  searchError?: ?string,
};

type State = {
  isLoading: boolean,
  searchResults: Array<{
    path: string,
    type: string,
    image?: {
      localFile?: {
        childImageSharp: {
          fixed: any,
        },
      },
    },
    title: string,
    excerpt: string,
  }>,
  search: ?{ [string]: Function },
  searchQuery?: string,
};

const StyledButton = styled(Button)`
  margin: 0 auto 1em auto;
`;

const StyledTextStyle = styled(TextStyle)`
  text-align: center;
  font-style: italic;
  display: block;
  margin-top: 1em;
`;

const NoResultsFound = styled(StyledTextStyle)`
  text-align: left;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

const StyledLink = styled(Link)`
  text-align: center;
  display: block;
`;

const StyledSearchIcon = styled(SearchIcon)`
  transform: scale(1.4);
  transform-origin: left center;
`;

const NumberResultsLabel = styled.p`
  margin-top: 1.5em;
  color: #8e8e8e;
  margin-bottom: 0.5em;
`;

const StyledTextField = styled(TextField)`
  display: inline-block;
  margin-top: 0;

  input {
    width: 100%;
    font-size: 1.45em;
  }

  label {
    display: none;
  }
`;

const PageType = styled.div`
  color: #8e8e8e;
  font-size: 0.9em;
`;

const SearchResult = styled.div`
  padding: 1.5em 0em 1.5em 0em;
  border-bottom: 1px solid #ccc;
  display: flex;
`;

const ResultPreview = styled.div`
  padding: 0em 1.5em 0em 0em;
  flex: 3;

  & .highlight {
    background-color: #E6F3ED;
  }
`;

const ResultImg = styled(Img)`
  flex: 1;
  border-radius: 4px;
`;

const SearchResultTitle = styled.div`
  font-family: var(--font-family-heading);
  font-size: 1.2em;
  font-weight: 500;
`;

const SearchResultExcerpt = styled.div``;

class ClientSearch extends Component<Props, State> {
  // Rule contradicts Flow checks:
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    showImages: false,
    indexContent: false,
    cancelSearch: () => {},
    loadingFullSearchPage: () => {},
    resultsLimit: null,
    searchQuery: '',
    queryUpdated: null,
    searchError: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      searchResults: [],
      search: null,
      searchQuery: props.searchQuery,
    };
  }

  async componentDidMount() {
    const { searchQuery } = this.state;

    this.rebuildIndex(() => {
      if (searchQuery) {
        this.searchData();
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { searchQuery: currentPropsSearchQuery } = this.props;
    const { searchQuery: currentStateSearchQuery } = this.state;

    // When searchQuery prop updated, update state and run search
    if (currentPropsSearchQuery !== currentStateSearchQuery
      && currentPropsSearchQuery !== prevProps.searchQuery) {
      // Safe to update state here because the condition checks it is safe:
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchQuery: currentPropsSearchQuery }, () => {
        this.searchData();
      });
    }
  }

  rebuildIndex = (callback: () => void) => {
    const { indexContent, pagesMap } = this.props;

    const searchIndex = elasticlunr();
    searchIndex.setRef('path');
    searchIndex.addField('title');
    searchIndex.addField('excerpt');
    if (indexContent) {
      searchIndex.addField('content');
    }

    Object.values(pagesMap).forEach((page) => {
      searchIndex.addDoc(page);
    });

    this.setState({ search: searchIndex, isLoading: false }, callback);
  };

  searchData = (event?: SyntheticEvent<HTMLInputElement>) => {
    const { pagesMap, resultsLimit, queryUpdated } = this.props;
    const { search, searchQuery } = this.state;

    if (!search) {
      // Index not yet ready
      return;
    }

    const newSearchQuery = event ? event.currentTarget.value : (searchQuery || '');

    // Run the search
    let queryResult = search.search(newSearchQuery, {
      fields: {
        title: { boost: 3 },
        excerpt: { boost: 2 },
        content: { boost: 1 },
      },
      expand: true,
    });

    if (resultsLimit) {
      queryResult = queryResult.slice(0, resultsLimit);
    }

    // Lookup each search result by ref
    const searchResults = queryResult.map((result) => {
      const page = pagesMap[result.ref];

      return {
        path: page.path,
        type: page.type,
        image: page.image,
        title: addHighlights(page.title, newSearchQuery),
        excerpt: addHighlights(page.excerpt, newSearchQuery),
      };
    });

    this.setState({ searchQuery: newSearchQuery, searchResults }, () => {
      if (queryUpdated) {
        queryUpdated(newSearchQuery);
      }
    });
  };

  handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { searchQuery } = this.state;
    const { cancelSearch } = this.props;

    if (event.key === 'Enter') {
      navigate(`${paths.search}${searchQuery ? `?s=${searchQuery}` : ''}`);
    }
    if (event.key === 'Escape' && cancelSearch) {
      cancelSearch();
    }
  };

  render() {
    const {
      showImages, resultsLimit, cancelSearch,
      loadingFullSearchPage, indexContent, searchError,
    } = this.props;
    const { searchResults, searchQuery, isLoading } = this.state;

    const queryResults = searchQuery === '' ? [] : (searchResults || []);
    return (
      <div className="quick-search">
        {!resultsLimit && !isLoading && (
          <NumberResultsLabel className={searchQuery ? '' : 'invisible'}>
            Displaying
            {` ${queryResults.length} `}
            results for:
          </NumberResultsLabel>
        )}
        <form onSubmit={this.handleSubmit} className="search-bar-outer">
          <div className="search-bar">
            <StyledSearchIcon className="search-icon" />
            <StyledTextField
              className="search-input"
              inputType="search"
              name="search"
              placeholder="Search"
              value={searchQuery}
              onKeyDown={this.handleKeyDown}
              onChange={this.searchData}
              label="Search"
              autoFocus
            />
            <CancelButton className="cancel-button" title="Cancel Search" onClick={cancelSearch} />
          </div>
        </form>

        <div className="search-results">
          {searchError && (
            <StyledTextStyle variation="negative">
              {searchError}
            </StyledTextStyle>
          )}
          {isLoading && (
            <StyledTextStyle variation="neutral">Loading</StyledTextStyle>
          )}
          {!isLoading && searchQuery && queryResults.length === 0 && (
            <NoResultsFound variation="neutral">
              {`No${indexContent ? '' : ' quick'} results found.`}
            </NoResultsFound>
          )}
          {!isLoading && queryResults.map((item) => (
            <Link to={item.path} key={`row_${item.path}`}>
              <SearchResult className="result">
                <ResultPreview>
                  <PageType>
                    {item.type}
                  </PageType>
                  <SearchResultTitle dangerouslySetInnerHTML={{ __html: item.title }} />
                  <SearchResultExcerpt dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                </ResultPreview>
                {showImages && item.image && (
                  <ResultImg
                    fixed={item.image}
                    alt=""
                  />
                )}
              </SearchResult>
            </Link>
          ))}
          {resultsLimit && !isLoading && searchQuery && (
            <StyledLink to={`${paths.search}?s=${searchQuery}`} onClick={loadingFullSearchPage}>
              <StyledTextStyle variation="neutral" as="p" className="may-be-missing-results">This quick search may not show all results.</StyledTextStyle>
              <StyledButton>See All Results</StyledButton>
            </StyledLink>
          )}
        </div>
      </div>
    );
  }
}
export default ClientSearch;
