// @flow
import * as React from 'react';
import styled from 'styled-components';

import type { MenuItem as MenuItemType } from '../../types';
import { NavbarDropdownBox } from '../../styles';
import MenuItem from './MenuItem';
import Dropdown from '../Dropdown';

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;

  a {
    display: block;
    font-size: 27px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    margin-left: 40px;

    a {
      display: flex;
      height: calc(var(--navbar-logo-height) + 4px);
      align-items: flex-end;
      font-size: 18px;
      margin-right: 24px;
    }
  }
`;

const DropdownMenu = styled(NavbarDropdownBox)`
  a {
    height: auto;
    margin-right: 0;
  }
`;

type Props = {
  items: MenuItemType[],
  mobile?: boolean,
  as?: React.ElementType,
  className?: string,
}

const defaultProps = {
  mobile: false,
  as: 'nav',
  className: '',
};

const Menu = ({
  items,
  mobile,
  as,
  className,
}: Props) => (
  <Wrapper as={as} className={className}>
    {items.map((menuItem) => {
      const { children, ...rest } = menuItem;
      if (children && children.length) {
        if (mobile) {
          return (
            <React.Fragment key={`items-${menuItem.id}`}>
              <MenuItem key={menuItem.id} item={rest} />
              {children.map((item) => <MenuItem key={item.id} item={item} />)}
            </React.Fragment>
          );
        }

        return (
          <Dropdown
            key={menuItem.id}
            buttonContent={menuItem.title}
            buttonStyles={{ height: 'var(--navbar-height)', fontSize: '18px' }}
          >
            <DropdownMenu>
              {children.map((item) => <MenuItem key={item.id} item={item} />)}
            </DropdownMenu>
          </Dropdown>
        );
      }

      return <MenuItem key={menuItem.id} item={rest} />;
    })}
  </Wrapper>
);

Menu.defaultProps = defaultProps;

export default Menu;
