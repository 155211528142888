// @flow
import * as React from 'react';
import { StaticQuery, graphql, Link as GatsbyLink } from 'gatsby';

const relativeURL = (url: string, rootUrl: string) => url.replace(new RegExp(`^${rootUrl}/?`, 'g'), '/');

type Props = {
  data: {
    siteMeta: {
      url: string,
    },
  },
  children: React.Node,
  to: string,
  target?: string,
  rel?: string,
  activeStyle: { [key: string]: string },
  activeClassName: string,
  partiallyActive: boolean,
  state: {},
  replace: boolean,
  className?: string,
  onClick?: Function,
};

const defaultProps = {
  target: undefined,
  rel: undefined,
  className: undefined,
  onClick: null,
};

const Link = ({
  data,
  children,
  to,
  target,
  rel,
  activeStyle,
  activeClassName,
  partiallyActive,
  state,
  replace,
  className,
  onClick,
}: Props) => {
  const internal = (to.startsWith('/') && !to.startsWith('//')) // e.g. //example.com/some/path is external while /search is internal
    || to.startsWith(data.siteMeta.url);

  if (internal) {
    return (
      <GatsbyLink
        to={relativeURL(to, data.siteMeta.url)}
        rel={rel}
        activeStyle={activeStyle}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        state={state}
        replace={replace}
        className={className}
        onClick={onClick}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} target={target} rel={rel} className={className}>
      {children}
    </a>
  );
};

Link.defaultProps = defaultProps;

export default (props: any) => (
  <StaticQuery
    query={graphql`
      query {
        siteMeta: wordpressSiteMetadata {
          url
        }
      }
    `}
    // eslint-disable-next-line react/jsx-props-no-spreading
    render={(data) => <Link data={data} {...props} />}
  />
);
