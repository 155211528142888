// @flow
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import type { MenuItem } from '../types';
import { paths } from '../../config';
import { Container, IntroText } from '../styles';
import Link from './Link';
import NewsletterForm from './NewsletterForm';

const StyledFooter = styled.footer`
  margin: var(--footer-top-space) 0 1.8em;
`;

const FooterGrid = styled.div`
  > div {
    margin-bottom: 40px;

    > p:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    > div {
      margin-bottom: 80px;
    }
  }

  @media (min-width: 1200px) {
    > div {
      margin-bottom: 110px;
    }
  }
`;

const PopularArticles = styled.div`
  h3 {
    margin-bottom: 2em;
  }
`;

const Article = styled(Link)`
  display: flex;
  box-shadow: none;

  & + & {
    margin-top: 20px;
  }

  &:focus,
  &:hover {
    box-shadow: none;
  }
`;

const ArticleImage = styled(Img)`
  flex: 0 0 102px;
  margin-right: 1.5em;
  border-radius: 2px;
  overflow: hidden;
`;

const ArticleContent = styled.div`
  span {
    box-shadow: inset 0 -2px var(--color-link-underline);
    transition: box-shadow 0.4s cubic-bezier(0.15, 0.53, 0.35, 1);
  }

  ${Article}:focus & span,
  ${Article}:hover & span {
    box-shadow: inset 0 -6px var(--color-link-underline);
  }
`;

const FooterBottom = styled.div`
  color: #c3c3c3;

  a {
    color: inherit;
  }
`;

const SomethingMissing = styled.div`
  p {
    margin-bottom: 0;
  }

  a {
    white-space: nowrap;
  }
`;

const FooterMenu = styled.nav`
  margin: 2.5em -1.25em 0;

  a {
    display: inline-block;
    margin: 0.5em 1.25em;
    color: inherit;
    white-space: nowrap;
  }

  @media (min-width: 992px) {
    margin-top: 0;
  }
`;

type Props = {
  data: {
    menus: {
      nodes: Array<{
        items: MenuItem[],
      }>,
    },
    wordpressAcfOptions: {
      options: {
        popularArticles: Array<{
          id: string,
          title: string,
          path: string,
          featuredImage?: {
            alt: string,
            localFile?: {
              childImageSharp: {
                fluid: any,
              },
            },
          },
        }>,
      },
    },
  },
};

const Footer = ({ data: { menus, wordpressAcfOptions } }: Props) => (
  <StyledFooter>
    <Container>
      <FooterGrid className="row">
        <div className="col-md-6 order-md-1">
          <h3 className="h1">What does ethical mean?</h3>
          <IntroText>
            We know that “ethical” isn’t an objective word —
            it can mean different things to different people.
            For us, ethical means moving in the direction of
            least possible harm against other people, animals and the planet.
          </IntroText>
          <IntroText>
            Read more about it&nbsp;
            <Link to={paths.faq}>here</Link>
            .
          </IntroText>
        </div>
        <div className="col-md-6 order-md-3">
          <h3>Who are we?</h3>
          <IntroText small>Seriously though… Who? What? Why?!</IntroText>
          <p>
            Ethical.net is a collaborative platform for discovering and sharing ethical product
            alternatives — whether that means purchasing from a social enterprise, thrift shopping,
            or learning how to fix your old phone instead of buying a new one.
          </p>
          <p>
            Check out&nbsp;
            <Link to={paths.about}>the Team</Link>
            .
          </p>
        </div>
        <div className="col-md-5 order-md-2 offset-md-1 col-lg-4">
          {wordpressAcfOptions.options.popularArticles && (
            <PopularArticles>
              <h3>Popular Articles</h3>
              <nav>
                {wordpressAcfOptions.options.popularArticles.map((article) => (
                  <Article key={article.id} to={article.path}>
                    {article.featuredImage && article.featuredImage.localFile && (
                      <ArticleImage
                        fluid={{
                          ...article.featuredImage.localFile.childImageSharp.fluid,
                          aspectRatio: 1.5,
                        }}
                        alt={article.featuredImage.alt}
                      />
                    )}
                    <ArticleContent>
                      {/* eslint-disable-next-line react/no-danger */}
                      <span dangerouslySetInnerHTML={{ __html: article.title }} />
                    </ArticleContent>
                  </Article>
                ))}
              </nav>
            </PopularArticles>
          )}
        </div>
        <div className="col-md-5 order-md-4 offset-md-1 col-lg-4">
          <h3>The Newsletter</h3>
          <p>
            <>Fortnightly guides to ethical living and news on the best new ethical brands </>
            <span role="img" aria-label="Person Raising Both Hands in Celebration">🙌</span>
          </p>
          <NewsletterForm inline />
        </div>
      </FooterGrid>
      <FooterBottom className="row align-items-end">
        <SomethingMissing className="col-lg-6">
          <p>
            Something missing? We want to know!
            {' '}
            <Link to={paths.contact}>Tell us everything.</Link>
          </p>
        </SomethingMissing>
        <div className="col-lg-6">
          <FooterMenu>
            {menus.nodes[0].items.map((item) => (
              <Link key={item.id} to={item.url} target={item.target}>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: item.title }} />
              </Link>
            ))}
          </FooterMenu>
        </div>
      </FooterBottom>
    </Container>
  </StyledFooter>
);

export default () => (
  <StaticQuery
    query={graphql`
      query {
        menus: allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "footer"}}) {
          nodes {
            items {
              id: wordpress_id
              url
              title
              order
              target
            }
          }
        }
        wordpressAcfOptions {
          options {
            popularArticles: most_read_articles {
              id: wordpress_id
              path
              title
              featuredImage: featured_media {
                alt: alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 104) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
);
