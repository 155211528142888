/**
 * Checks if any of the query words matches at the current position.
 *
 * @param {string[]} queryWords - all words in the query (lowercased)
 * @param {number} matchPosition - position to check for in text field
 * @param {string} lowercasedTextField - the text field to check for a match in
 * @return {string|void} the matched query word, or null
 */
function checkForMatchedWord(queryWords, matchPosition, lowercasedTextField) {
  return queryWords.find((word) => {
    const expectedEndPosition = matchPosition + word.length;
    return word === lowercasedTextField.slice(matchPosition, expectedEndPosition);
  });
}

/**
 * Highlight any matching word at a specified position.
 *
 * @param {string} textField - text field to highlight, maintaining original case
 * @param lowercasedTextField - text field to highlight, lowercased
 * @param matchPosition - position to check for in text field
 * @param {string[]} queryWords - all words in the query (lowercased)
 * @return {{highlighted: string, nextPosition: number}} if a word was matched, a string with
 * highlighting added, otherwise empty string. Also returns the nextPosition at which to
 * search from.
 */
function highlightWordsAt(textField, lowercasedTextField, matchPosition, queryWords) {
  const matchedWord = checkForMatchedWord(queryWords, matchPosition, lowercasedTextField);

  let nextPosition = matchPosition;
  let highlighted = '';
  if (matchedWord) {
    nextPosition += matchedWord.length;
    highlighted += '<span class="highlight">';
    highlighted += textField.substring(matchPosition, nextPosition);
    highlighted += '</span>';
  }

  return { highlighted, nextPosition };
}

/**
 * Adds highlights to a given string for any words in the query string.
 *
 * @param {string} textField
 * @param {string} query
 * @return {string} Content of textField with highlights added
 */
function addHighlights(textField, query) {
  const lowercasedTextField = textField.toLowerCase();
  const queryWords = query.toLowerCase().split(' ')
    .filter((s) => s.length > 1); // Only highlight 2 characters or more

  // First see if there is a match at the beginning of the string:
  const initialMatch = highlightWordsAt(textField, lowercasedTextField, 0, queryWords);
  let { nextPosition: position, highlighted: stringWithHighlights } = initialMatch;
  const wordBeginningRegExp = /[^A-Za-z]/g; // Any character that is not alphabetical might proceed a word
  let match;
  do {
    // Set the regex to search from current position
    wordBeginningRegExp.lastIndex = position;
    // Find the next matching non-word character (if any):
    match = wordBeginningRegExp.exec(lowercasedTextField);

    if (match === null) {
      stringWithHighlights += textField.substring(position);
    } else {
      // This is a potential position at which to match a word:
      const potentialMatchPosition = match.index + 1;
      // Add any characters since last match to the highlight string:
      stringWithHighlights += textField.substring(position, potentialMatchPosition);
      // A word may or may not be highlighted at this position:
      const { highlighted, nextPosition } = highlightWordsAt(
        textField, lowercasedTextField, potentialMatchPosition, queryWords,
      );
      stringWithHighlights += highlighted;
      position = nextPosition;
    }
  } while (match !== null);

  return stringWithHighlights;
}

module.exports = addHighlights;
