/**
 * Adds title and other SEO related meta tags to the head
 *
 * @flow
 */

import React from 'react';
import Helmet from 'react-helmet';

import type { Meta, Link } from '../types';
import { normalizeMeta } from '../utils';

type Props = {
  title: string,
  canonical?: ?string,
  lang?: string,
  meta?: Meta[],
  link?: Link[],
};

const SEO = ({
  title,
  lang,
  canonical,
  meta,
  link,
}: Props) => {
  const normalizedMeta = meta ? normalizeMeta(meta) : [];
  const defaultLinks = canonical ? [{
    rel: 'canonical',
    href: canonical,
  }] : [];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={defaultLinks.concat(link)}
      meta={normalizedMeta}
    />
  );
};

SEO.defaultProps = {
  lang: 'en_US',
  canonical: null,
  meta: [],
  link: [],
};

export default SEO;
