// @flow
import React from 'react';
import styled from 'styled-components';

import CookiesIcon from '../images/cookies.svg';
import { Button } from '../styles';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 14px;
  background: #f1f6ff;
  border-top: 1px solid #c5c5c5;
  box-shadow: rgba(0,0,0,0.3) 0px 2px 35px 0px;
  z-index: 9999999999;

  svg {
    display: none;
  }

  @media (min-width: 768px) {
    left: 50%;
    right: auto;
    bottom: 12px;
    width: 100%;
    max-width: 620px;
    transform: translate(-50%, 0);
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #c5c5c5;

    svg {
      display: block;
      flex-shrink: 0;
    }
  }

  @media (min-width: 992px) {
    max-width: 900px;
  }
`;

const CookieNotice = styled.div`
  margin: 0 16px 0 0;

  @media (min-width: 768px) {
    margin: 0 16px;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: auto;

  button {
    cursor: pointer;
  }
`;

type Props = {
  onCompliance: () => void,
};

function CookieConsent(props: Props) {
  const { onCompliance } = props;

  return (
    <Wrapper
      role="dialog"
      aria-live="polite"
      aria-label="cookie consent"
      aria-describedby="ep-cookie-consent__desc"
    >
      <CookiesIcon />
      <CookieNotice id="ep-cookie-consent__desc">
        <>We only use cookies to optimise site functionality and analytics. </>
        <a
          aria-label="learn more about cookies"
          href="https://www.iubenda.com/privacy-policy/34877317/cookie-policy"
          rel="noreferrer nofollow noopener external"
          target="_blank"
        >
          Learn more
        </a>
      </CookieNotice>
      <ButtonWrapper>
        <Button size="slim" aria-label="accept cookie policy" onClick={onCompliance}>
          OK
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default CookieConsent;
