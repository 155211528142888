// @flow
import useMedia from 'use-media';

function useMediaBreakpoints() {
  const isDesktop = useMedia({ minWidth: 992 });
  // useMedia must come first or it will break hooks order
  const isTablet = useMedia({ minWidth: 768 }) && !isDesktop;
  const isMobile = !isDesktop && !isTablet;

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
}

export default useMediaBreakpoints;
