// Note that only some process.env variables are made available by the Gatsby webpack config
// If you want to use an env var here, ensure it will work client-side
// @see https://www.gatsbyjs.org/docs/environment-variables/#accessing-environment-variables-in-javascript

// It is safe to use NODE_ENV and GATSBY_* in the frontend as webpack makes them available
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

// @see https://docs.netlify.com/site-deploys/overview/#deploy-contexts
// @see https://docs.netlify.com/configure-builds/environment-variables/#build-metadata
// We have made process.env.CONTEXT available to the frontend in gatsby-node.js
const deployContext = process.env.CONTEXT;

// Discourse config depends on deploy context...
// Defaults for no deploy context (local dev):
let apiUrl = 'http://localhost:8080';
let communityUrl = 'http://community.ethical.local';
if (deployContext === 'production') {
  // TODO: switch to proper API address (see https://github.com/AppliedEthics/ethical-gatsby/issues/86)
  apiUrl = 'https://us-central1-stalwart-cable-255818.cloudfunctions.net';
  communityUrl = 'https://community.ethical.net';
} else if (deployContext) {
  // Usually `deploy-preview` or `branch-deploy`
  // If you don't run a copy of Discourse in local dev, you'll want to use our sandbox.
  // To use the sandbox in dev, set the CONTEXT env var to anything not recognized by Netlify.
  apiUrl = 'https://us-central1-ethical-sandbox-api.cloudfunctions.net';
  communityUrl = 'https://sandbox.community.ethical.net';
}

module.exports = {
  siteUrl: activeEnv === 'production' ? 'https://ethical.net' : 'http://localhost:8000',
  wordPressUrl: activeEnv === 'production' ? 'https://wp.ethical.net' : 'http://ethical.local',
  apiUrl,
  communityUrl,
  paths: {
    search: '/search',
    alternatives: '/alternatives',
    alternativeCategories: '/alternative-categories',
    alternativeTags: '/alternative-tags',
    blog: '/blog',
    contact: '/contact',
    about: '/about',
    resources: '/resources',
    shops: '/shops',
    faq: '/faq',
  },
  cookieConsentCookieName: 'et_cookie_consent',
  itemSubmissionFormAction: 'https://formspree.io/mjvvdeyn',
  titleWordSlides: [{
    word: 'ethical',
    color: '#4a86e8',
  }, {
    word: 'sustainable',
    color: '#77a543',
  }, {
    word: 'kind',
    color: '#d88200',
  }, {
    word: 'joyful',
    color: '#2d8888',
  }, {
    word: 'compassionate',
    color: '#ff6700',
  }, {
    word: 'transparent',
    color: '#9900ff',
  }, {
    word: 'enough',
    color: '#0000ff',
  }, {
    word: 'solidarity',
    color: '#ff0000',
  }, {
    word: 'caring',
    color: '#990086',
  }, {
    word: 'cooperation',
    color: '#198bf1',
  }, {
    word: 'generous',
    color: '#ea6363',
  }, {
    word: 'humane',
    color: '#6d9603',
  }, {
    word: 'friendly',
    color: '#45818e',
  }, {
    word: 'open',
    color: '#179a88',
  }, {
    word: 'understanding',
    color: '#ff7800',
  }, {
    word: 'mindful',
    color: '#b96792',
  }, {
    word: 'regenerative',
    color: '#38761d',
  }, {
    word: 'sharing',
    color: '#c37625',
  }, {
    word: 'peace',
    color: '#2d8888',
  }, {
    word: 'honesty',
    color: '#1155cc',
  }, {
    word: 'considerate',
    color: '#a64d79',
  }, {
    word: 'green',
    color: '#419c10',
  }, {
    word: 'community',
    color: '#ad8200',
  }, {
    word: 'thoughtful',
    color: '#8e7cc3',
  }, {
    word: 'purpose',
    color: '#679055',
  }, {
    word: 'conscious',
    color: '#cc0000',
  }, {
    word: 'slow',
    color: '#da6464',
  }, {
    word: 'nature',
    color: '#3f9a3f',
  }, {
    word: 'collaboration',
    color: '#558fc3',
  }],
};
