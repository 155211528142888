// @flow
import type { Meta } from './types';
import type { PostListItemType } from './components/PostListItem';

/**
 * Removes empty properties from object
 *
 * @param {object} obj The object
 * @return {object}
 */
export const removeEmptyProp = (obj: {}) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] != null) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

/**
 * Removes empty pros from array of meta objects
 *
 * @param {object[]} meta Array of meta objects
 */
export const normalizeMeta = (meta: Meta[]): any => meta.map(removeEmptyProp);

/**
 * Generates array of page numbers for pagination
 *
 * @param {number} current Current page number
 * @param {number} total Total number of pages
 * @return {(string|number)[]} An array of page numbers and dots string
 */
export const generatePagination = (current: number, total: number): Array<number | 'DOTS'> => {
  const delta = 1;
  const range = [];
  const rangeWithDots = [];

  range.push(1);
  for (let i = current - delta; i <= current + delta; i += 1) {
    if (i < total && i > 1) {
      range.push(i);
    }
  }
  range.push(total);

  let l;
  range.forEach((i) => {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('DOTS');
      }
    }
    rangeWithDots.push(i);
    l = i;
  });

  return rangeWithDots;
};

export const pluralize = (
  count: number,
  singular: string,
  plural: string,
) => (count === 1 ? singular : plural);

/**
 * Renames alternativeTags prop to tags for guides
 *
 * @param {object} node
 */
export const normalizeTags = (
  node: PostListItemType & {
    alternativeTags?: Array<{
      path: string,
      name: string,
    }>,
  },
) => {
  if (node.type === 'post') {
    return node;
  }

  const { alternativeTags, ...rest } = node;

  return { ...rest, tags: alternativeTags };
};

export const postJson = (
  url: string,
  postData: { [key: string]: any},
  successCallback: ({ [key: string]: any}) => void,
  errorCallback: () => void,
) => {
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      response.json().then((data) => {
        successCallback(data);
      });
    } else { // non-success status code
      errorCallback();
    }
  }).catch(errorCallback);
};

export const getJson = (
  url: string,
  successCallback: (any) => void,
  errorCallback: () => void,
) => {
  fetch(url).then((response) => {
    if (response.ok) {
      response.json().then((data) => {
        successCallback(data);
      });
    } else { // non-success status code
      errorCallback();
    }
  }).catch(errorCallback);
};
