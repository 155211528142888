// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';

import { cookieConsentCookieName } from '../../config';
import { checkForSingleSignOn, getUserData, dismissJustLoggedIn } from '../services/auth';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container } from '../styles';
import './layout.scss';
import LoadingIndicator from '../components/LoadingIndicator';
import FadingNotification from '../components/FadingNotification';
import CookieConsent from '../components/CookieConsent';

const Page = styled.div`
  overflow-x: hidden;
`;

const Content = styled.div`
  ${(props) => props['data-background'] && css`
    padding: var(--header-bottom-space) 0 calc(1/2 * var(--footer-top-space));
    background: ${props['data-background']};
    margin-bottom: calc(-1/2 * var(--footer-top-space));
  `}
`;

type Props = {
  children: React.Node,
  fluid?: boolean,
  fullwidth?: boolean,
  noHeaderBottomSpace?: boolean,
  backgroundColor?: string,
  className?: string,
};

const defaultProps = {
  fluid: false,
  fullwidth: false,
  noHeaderBottomSpace: false,
  backgroundColor: undefined,
  className: undefined,
};

const DefaultLayout = ({
  children,
  fluid,
  fullwidth,
  noHeaderBottomSpace,
  backgroundColor,
  className,
}: Props) => {
  const userData = getUserData();
  const [isLoggedIn, setLoggedIn] = React.useState(Boolean(userData));
  const [isVerifyingLogin, setVerifyingLogin] = React.useState(false);
  const [cookieConsentVisible, setCookieConsentVisible] = React.useState(false);

  React.useEffect(() => {
    const cookiePattern = new RegExp(`^(.*;)?\\s*${cookieConsentCookieName}\\s*=\\s*[^;]+(.*)?$`);
    if (!document.cookie.match(cookiePattern)) {
      setCookieConsentVisible(true);
    }
  }, []);

  React.useEffect(() => {
    checkForSingleSignOn(() => setLoggedIn(true), setVerifyingLogin);
  });

  React.useEffect(() => {
    if (userData && userData.justLoggedIn) {
      const timer = setTimeout(dismissJustLoggedIn, 2000);
      return () => clearTimeout(timer);
    }
    return undefined; // to fix flow error
  });

  const handleCookieConsentCompliance = React.useCallback(() => {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 6);
    document.cookie = `${cookieConsentCookieName}=1; expires=${expires.toUTCString()}; path=/`;
    setCookieConsentVisible(false);
  });

  const contentMarkup = fullwidth ? children : (
    <Container fluid={fluid}>
      {children}
    </Container>
  );

  return (
    <Page className={className}>
      <Header
        noBottomSpace={noHeaderBottomSpace || backgroundColor}
        isLoggedIn={isLoggedIn}
        setLoggedIn={setLoggedIn}
      />
      <Content id="content" data-background={backgroundColor}>
        {contentMarkup}
      </Content>
      <Footer />

      {isVerifyingLogin && <LoadingIndicator message="Verifying Ethical Community login..." />}
      {!isVerifyingLogin && userData && userData.justLoggedIn && (
        <FadingNotification>
          You are now logged in as
          <> </>
          <strong>{userData.username}</strong>
        </FadingNotification>
      )}
      {cookieConsentVisible && <CookieConsent onCompliance={handleCookieConsentCompliance} />}
    </Page>
  );
};

DefaultLayout.defaultProps = defaultProps;

export default DefaultLayout;
