// @flow
import * as React from 'react';
import { Manager, Reference, Popper } from 'react-popper';
// eslint-disable-next-line import/no-unresolved
import type { Properties as CSSProperties } from 'csstype';
import styled from 'styled-components';

import ToggleButton from './ToggleButton';

const DropdownWrapper = styled.div`
  z-index: 99;
`;

type Props = {
  buttonContent: React.Node,
  buttonStyles?: CSSProperties<HTMLElement>,
  containerStyles?: CSSProperties<HTMLElement>,
  children: React.Node,
}

const defaultProps = {
  buttonStyles: {},
  containerStyles: {},
};

const Dropdown = (props: Props) => {
  const {
    buttonContent,
    children,
    buttonStyles,
    containerStyles,
  } = props;

  const [active, setActive] = React.useState(false);
  const buttonContentMarkup = typeof buttonContent === 'string'
    ? <span>{buttonContent}</span>
    : buttonContent;

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <ToggleButton
            active={active}
            ref={ref}
            onClick={() => setActive(!active)}
            style={buttonStyles}
          >
            {buttonContentMarkup}
          </ToggleButton>
        )}
      </Reference>
      {active && (
        <Popper>
          {({ ref, style, placement }) => (
            <DropdownWrapper
              ref={ref}
              style={{ ...containerStyles, ...style }}
              data-placement={placement}
            >
              {children}
            </DropdownWrapper>
          )}
        </Popper>
      )}
    </Manager>
  );
};

Dropdown.defaultProps = defaultProps;

export default Dropdown;
