// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from '../styles';
import TextField from './TextField';

const StyledTextField = styled(TextField)``;

const SignUpButton = styled(Button)`
  white-space: nowrap;
`;

const Form = styled.form`
  display: flex;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
  align-items: ${(props) => (props.inline ? 'flex-end' : 'flex-start')};

  ${StyledTextField} {
    input {
      width: 100%;
      margin-bottom: ${(props) => (props.inline ? 0 : '27px')};

      ${(props) => props.inline && css`
        margin-right: ${props.large ? '53px' : '20px'};
      `}
    }
  }
`;

type Props = {
  large?: boolean,
  inline?: boolean,
  className?: string,
};

const defaultProps = {
  large: false,
  inline: false,
  className: '',
};

const NewsletterForm = ({ large, inline, className }: Props) => (
  <Form large={large} inline={inline} className={className} action="https://www.nwslettr.net/subscribe" method="post">
    <input type="hidden" name="category_id" value="8" />
    <StyledTextField
      label="Your Email"
      large={large}
      type="email"
      name="email"
      required
    />
    <input
      type="text"
      style={{ display: 'none' }}
      name="twitter-handle"
      placeholder="@username"
    />

    <SignUpButton
      type="submit"
      size={large ? 'large' : 'normal'}
    >
      Sign Up
    </SignUpButton>
  </Form>
);

NewsletterForm.defaultProps = defaultProps;

export default NewsletterForm;
