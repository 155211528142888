// @flow
import React from 'react';

import Link from '../Link';

type Props = {
  item: {
    url: string,
    title: string,
    target: string,
  },
  className?: string,
};

const defaultProps = {
  className: '',
};

const MenuItem = ({
  item: {
    url,
    title,
    target,
  },
  className,
}: Props) => (
  <Link to={url} target={target} className={className}>
    {title}
  </Link>
);

MenuItem.defaultProps = defaultProps;

export default MenuItem;
