// @flow
import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled, { keyframes, css } from 'styled-components';
// Note: @reach/router is a gatsby dependency
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from '@reach/router';

import { getJson } from '../../utils';
import type { MenuItem } from '../../types';
import { paths } from '../../../config';
import { prepareLogin, loginRedirect } from '../../services/auth';
import {
  Container, Button, TextButton, DropdownBox,
} from '../../styles';
import ScreenReaderText from '../ScreenReaderText';
import Link from '../Link';
import Logo from '../../images/logo.svg';
import SearchIcon from '../../images/search.svg';
import Menu from './Menu';
import SearchModal from './SearchModal';
import ClientSearch from '../ClientSearch';
import LoadingIndicator from '../LoadingIndicator';
import UserInfo from './UserInfo';

const sortByOrder = (a, b) => a.order - b.order;

const StyledHeader = styled.header`
  margin-bottom: ${(props) => (props.noBottomSpace ? '0' : 'var(--header-bottom-space)')};
  color: #000;
  background: #fff;
  box-shadow: 0 2px 27px 12px rgba(0, 0, 0, 0.02);

  a {
    box-shadow: none;

    &:focus,
    &:hover {
      color: var(--color-text-primary);
      box-shadow: none;
    }
  }
`;

const Navbar = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  height: var(--navbar-height);
`;

const LogoLink = styled(Link)`
  svg {
    width: auto;
    height: var(--navbar-logo-height);
  }
`;

const SearchLink = styled(Link)``;

const LoginSignUpButton = styled(Button)`
  padding-left: 38px;
  padding-right: 38px;
  color: #fff;
  background: #0083d5;
  border-color: #0083d5;

  &:focus,
  &:hover {
    color: #fff;
    background: #000;
    border-color: #000;
  }
`;

const NavbarRight = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;

  ${SearchLink} {
    display: flex;
    align-items: center;
    height: var(--navbar-height);
    color: #d8d8d8;

    svg {
      transform: translateY(2px);
    }
  }

  ${LoginSignUpButton} {
    margin-right: 34px;
    margin-left: 47px;
  }
`;

const ToggleButton = styled(TextButton).attrs((props) => ({
  active: (props.isMenuVisible && !props.isMenuHiding) || props.isSearchVisible,
}))`
  position: ${(props) => (props.active ? 'fixed' : 'relative')};
  top: 0;
  right: ${(props) => (props.active ? 'var(--container-gutter-width)' : 0)};
  display: block;
  width: 44px;
  height: ${(props) => (props.active ? 'var(--navbar-height)' : '44px')};
  z-index: 101001;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: ${(props) => (props.active ? '-1px' : '-4px')};
    display: inline-block;
    width: 19px;
    height: 2px;
    background: var(--color-text-button);
    transform: ${(props) => (props.active ? 'rotate(45deg)' : 'none')};
    transform-origin: center;
    transition: ${(props) => (
    props.active
      ? 'transform 0.2s 0.1s, margin-top 0.1s'
      : 'transform 0.2s, margin-top 0.1s 0.2s')};
  }

  &::after {
    margin-top: ${(props) => (props.active ? '-1px' : '2px')};
    transform: ${(props) => (props.active ? 'rotate(-45deg)' : 'none')};
  }
`;

const MobileMenu = styled(Menu).attrs(() => ({ mobile: true }))``;

const expandFromCorner = keyframes`
  from {
    clip-path: circle(10% at top right);
  }

  to {
    clip-path: circle(100% at top right);
  }
`;
const shrinkToCorner = keyframes`
  from {
    clip-path: circle(100% at top right);
  }

  to {
    clip-path: circle(10% at top right);
  }
`;

const mobileMenuAnimationExpand = css`animation: ${expandFromCorner} 0.21s ease-in;`;
const mobileMenuAnimationShrink = css`animation: ${shrinkToCorner} 0.21s ease-out;`;

const MobileMenuModal = styled.div.attrs((props) => {
  let animation = 'animation: none;';
  if (props.isHiding) {
    animation = mobileMenuAnimationShrink;
  } else if (props.isVisible) {
    animation = mobileMenuAnimationExpand;
  }
  return { animation };
})`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 77px;
  background: #fff8e9;
  overflow-y: auto;
  z-index: 100000;
  ${(props) => props.animation};

  @media (min-width: 992px) {
    display: none;
  }

  ${SearchLink} {
    position: absolute;
    top: 0;
    left: var(--column-gutter);
    display: flex;
    width: 44px;
    height: var(--navbar-height);
    align-items: center;

    svg {
      transform: scale(1.4);
      transform-origin: left center;
    }
  }

  ${MobileMenu} {
    a {
      font-weight: 500;

      & + a {
        margin-top: 15px;
      }
    }
  }

  ${DropdownBox} {
    a {
      padding: 7px 0;
      font-size: 18px;
    }
  }
`;

const MobileLoginWrapper = styled.div`
  margin-top: 60px;
  font-size: 17px;
  line-height: 23px;
`;

const MobileButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  ${Button} + ${Button} {
    margin-left: 20px;
  }
`;

const MobileDivider = styled.hr`
  margin-top: 25px;
  margin-bottom: 25px;
  border: 0;
  border-top: 1px #fae1af solid;
`;

type Props = {
  data: {
    menus: {
      nodes: Array<{
        items: MenuItem[],
      }>,
    },
  },
  noBottomSpace: boolean,
  isLoggedIn: boolean,
  setLoggedIn: (boolean) => any,
};

const Header = ({
  data, noBottomSpace, isLoggedIn, setLoggedIn,
}: Props) => {
  const [{ items }] = data.menus.nodes;
  const menuItems = items.sort(sortByOrder);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMenuHiding, setIsMenuHiding] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isSearchHiding, setIsSearchHiding] = useState(false);
  const [pagesMap, setPagesMap] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [isLoadingLogin, setLoadingLogin] = useState(false);

  const loginSignUp = () => {
    setLoadingLogin(true);
    prepareLogin(loginRedirect);
  };

  const toggleMobileMenu = () => {
    setIsMenuVisible((previouslyVisible) => {
      if (previouslyVisible) {
        // Menu was visible: hide content first, then make it not visible after a short wait.
        setIsMenuHiding(true);
        setTimeout(() => {
          setIsMenuVisible(false);
          setIsMenuHiding(false);
        }, 200);
        return previouslyVisible; // do nothing
      }

      // Menu was not visible: make it visible immediately
      return !previouslyVisible;
    });
  };

  const closeModals = (/* event */) => {
    setIsMenuVisible(false);
    setIsSearchVisible(false);
  };

  const toggleSearch = (event) => {
    if (event) {
      event.preventDefault();
    }

    setIsSearchVisible((previouslyVisible) => {
      // Search was visible: hide content first, then make it not visible after a short wait.
      if (previouslyVisible) {
        setIsSearchHiding(true);
        setTimeout(() => {
          setIsSearchVisible(false);
          setIsSearchHiding(false);
        }, 200);
        return previouslyVisible; // do nothing
      }

      if (pagesMap === null) {
        getJson('/quicksearch.json',
          (responseData) => {
            setPagesMap(responseData);
          },
          () => {
            setSearchError('Could not load search (are you online?)');
          });
      }

      // Search was not visible: make it visible immediately
      return !previouslyVisible;
    });
  };

  const searchLinkMarkup = (
    <Location>
      {({ location }) => !location.pathname.startsWith(paths.search) && (
        <SearchLink to={paths.search} onClick={toggleSearch}>
          <SearchIcon />
          <ScreenReaderText>Search</ScreenReaderText>
        </SearchLink>
      )}
    </Location>
  );

  return (
    <StyledHeader noBottomSpace={noBottomSpace}>
      <Navbar>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <div className="d-none d-lg-block">
          <Menu items={menuItems} />
        </div>
        <NavbarRight>
          <div className="d-none d-lg-flex">
            {searchLinkMarkup}
          </div>
          <div className="d-none d-md-flex">
            {isLoggedIn ? (
              <UserInfo reverse setLoggedIn={setLoggedIn} />
            ) : (
              <LoginSignUpButton onClick={loginSignUp}>Log In / Sign Up</LoginSignUpButton>
            )}
          </div>
          <div className="d-lg-none">
            <ToggleButton
              isMenuVisible={isMenuVisible}
              isMenuHiding={isMenuHiding}
              isSearchVisible={isSearchVisible}
              onClick={isSearchVisible ? toggleSearch : toggleMobileMenu}
            />
          </div>
        </NavbarRight>
      </Navbar>

      {/* mobile menu modal */}
      <MobileMenuModal isVisible={isMenuVisible} isHiding={isMenuHiding}>
        <Container>
          {searchLinkMarkup}
          {isLoggedIn && (
            <>
              <UserInfo setLoggedIn={setLoggedIn} />
              <MobileDivider />
            </>
          )}
          <MobileMenu items={menuItems} />
          {!isLoggedIn && (
            <MobileLoginWrapper>
              Become an active part of our  community and help us make&nbsp;
              <strong>ethical the new normal.</strong>
              <MobileButtonGroup>
                <Button size="large" onClick={loginSignUp}>Log In / Sign Up</Button>
              </MobileButtonGroup>
            </MobileLoginWrapper>
          )}
        </Container>
      </MobileMenuModal>

      {/* quick search modal */}
      <SearchModal
        isVisible={isSearchVisible}
        isHiding={isSearchHiding}
        isMenuVisible={isMenuVisible}
      >
        {pagesMap && (
          <ClientSearch
            pagesMap={pagesMap}
            resultsLimit={3}
            cancelSearch={toggleSearch}
            loadingFullSearchPage={closeModals}
            searchError={searchError}
          />
        )}
      </SearchModal>

      {isLoadingLogin && <LoadingIndicator message="Connecting to the Ethical Community..." />}
    </StyledHeader>
  );
};

export default (props: {
  noBottomSpace: boolean,
  isLoggedIn: boolean,
  setLoggedIn: (boolean) => any,
}) => (
  <StaticQuery
    query={graphql`
      query {
        menus: allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "main"}}) {
          nodes {
            items {
              id: wordpress_id
              url
              title
              order
              target
              children: wordpress_children {
                id: wordpress_id
                url
                title
                order
                target
              }
            }
          }
        }
      }
    `}
    // eslint-disable-next-line react/jsx-props-no-spreading
    render={(data) => <Header data={data} {...props} />}
  />
);
