// @flow
import * as React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

// styled component with no styles but to support "as" prop
const Wrapper = styled.h1``;

const GuidePrefix = styled.span`
  color: #26985f;
`;

type Props = {
  title: string,
  isGuide: boolean,
  className?: string,
  // using "$as" instead of "as" to add support for modifying styles using `styled(Component)`.
  // @see https://github.com/styled-components/styled-components/issues/2449
  $as?: React.ElementType,
};

const defaultProps = {
  className: undefined,
  $as: 'h1',
};

const PostTitle = ({
  title,
  isGuide,
  className,
  $as,
}: Props) => (
  <Wrapper className={classnames('entry-title', className)} as={$as}>
    {isGuide && <GuidePrefix>Guide: </GuidePrefix>}
    {/* eslint-disable-next-line react/no-danger */}
    <span dangerouslySetInnerHTML={{ __html: title }} />
  </Wrapper>
);

PostTitle.defaultProps = defaultProps;

export default PostTitle;
